export const USER_SERVICE = {
  login: "api/login-token",
  register: "api/register-user",
};

export const CHECK_OUT_SERVICE = {
  CHECKOUT: "api/checkout",
};

export const PROFILE_SERVICE = {
  GET_PROFILE: "api/user-profile/view",
  EDIT_PROFILE: "api/user-profile/update",
};
